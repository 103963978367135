import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  AssetDto,
  Candidature,
  CandidatureStatusEnum,
  TenantCandidature,
  TypeDocumentEnum,
  User,
  UserAnalysisStatusEnum
} from '@core/models';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'el-buen-inquilino-candidature-rejected-card-item',
  templateUrl: './candidature-rejected-card-item.component.html'
})
export class CandidatureRejectedCardItemComponent implements OnInit, OnDestroy {
  @Input({ required: true }) candidature: Candidature;
  @Input({ required: true }) user: User;
  @Input({ required: true }) asset: AssetDto;

  candidatureStatusEnum = CandidatureStatusEnum;
  typeDocumentEnum = TypeDocumentEnum;
  savingAnalysis = false;

  savingAnalysis$ = new Subject<void>();
  getCandidatures$ = new Subject<void>();

  @Output() getCandidaturesEvent = new EventEmitter<AssetDto>();

  get tenantCandidatureList(): TenantCandidature[] {
    return this.candidature?.tenantCandidatureList || [];
  }

  get pendingToDownloadClass(): string {
    return this.candidatureUtils.pendingToDownloadClass(this.candidature);
  }

  get checkPendingToDownload(): boolean {
    return this.candidatureUtils.checkPendingToDownload(this.candidature);
  }

  get validationToShowDownloadAnalysis(): boolean {
    return this.candidatureUtils.validationToShowDownloadAnalysis(
      this.candidature
    );
  }

  get checkWithOutChecking(): boolean {
    return this.candidatureUtils.checkWithOutChecking(this.candidature);
  }

  constructor(private candidatureUtils: CandidatureUtilsService) {}

  ngOnInit(): void {
    this.subscribeToGetCandidaturesObs();
  }

  triggerGetCandidatures(): void {
    this.getCandidaturesEvent.emit(this.asset);
  }

  checkTenantsStatus(): boolean {
    return this.candidatureUtils.checkTenantsStatus(
      this.candidature,
      UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
      UserAnalysisStatusEnum.REPORTED_ANALYSIS
    );
  }

  presentInfoNoValidated(): void {
    this.candidatureUtils.presentInfoNoValidated();
  }

  presentInfoRating(): void {
    this.candidatureUtils.presentInfoRating();
  }

  checkTenantRejected(tenant: TenantCandidature): boolean {
    return this.candidatureUtils.checkTenantRejected(tenant);
  }

  candidatureRejectedAlert(): void {
    this.candidatureUtils.candidatureRejectedAlert();
  }

  previewReport(): void {
    this.candidatureUtils.previewReport(this.candidature);
  }

  ngOnDestroy(): void {
    this.getCandidatures$.next();
    this.getCandidatures$.complete();
    this.savingAnalysis$.next();
    this.savingAnalysis$.complete();
  }

  private subscribeToGetCandidaturesObs(): void {
    this.candidatureUtils
      .getGetCandidaturesObs()
      .pipe(takeUntil(this.getCandidatures$))
      .subscribe(() => this.triggerGetCandidatures());
  }
}
