/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { Sinister } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { prepareFile, UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { first } from 'rxjs';

import { AuthService } from '../../../core/services/auth/auth.service';
import { ButtonsRole } from '../../models/button.model';
import {
  SinisterExcelLoadResponse,
  UpdateDebtSinisterModalForm
} from '../../models/update-debt-sinister-modal.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-update-debt-sinister-modal',
  templateUrl: './update-debt-sinister-modal.component.html'
})
export class UpdateDebtSinisterModalComponent implements OnInit {
  form: FormGroup<UpdateDebtSinisterModalForm>;
  file: File;

  get fileControl(): FormControl {
    return this.form.controls.file;
  }

  get disableImportBtn(): boolean {
    return this.form.invalid;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private candidatureService: CandidaturesApiService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(role: ButtonsRole = ButtonsRole.CANCEL, data?: Sinister[]): void {
    this.modalController.dismiss(data, role);
  }

  async downloadTemplate(): Promise<void> {
    await this.loadingService.presentSecondLoader(
      'Generando plantilla...',
      false
    );

    this.candidatureService
      .getSinisterExcel(
        this.authService.user.portfolioId,
        this.authService.user.apiId,
        this.authService.user.id
      )
      .pipe(first())
      .subscribe({
        next: async (data: Blob) => {
          const blobUrl = window.URL.createObjectURL(data);

          // Crear un enlace oculto
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'siniestros_ebi.xlsx';

          // Simular clic en el enlace para iniciar la descarga
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Limpiar y eliminar el enlace
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);

          await this.loadingService.dismissSecondLoader();
        },
        error: async () => {
          await this.loadingService.dismissSecondLoader();
          const toast = await this.toastController.create({
            message: 'No se puede generar el Excel de siniestros.',
            duration: 2000,
            position: 'top',
            color: 'danger'
          });
          toast.present();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/typedef
  changeListener(event): void {
    this.file = prepareFile(event.target.files[0] as File);
  }

  async import(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    await this.loadingService.presentSecondLoader(null);

    this.candidatureService
      .updateSinistersByExcel(this.file)
      .pipe(first())
      .subscribe({
        next: async (resp: SinisterExcelLoadResponse) => {
          if (!resp.errorRow) {
            this.onSuccessImport(resp);
          } else {
            presentToast(
              this.toastController,
              resp.errorMessage,
              'danger',
              5000
            );
          }
          await this.loadingService.dismissSecondLoader();
        },
        error: async (_err: unknown) => {
          await this.loadingService.dismissSecondLoader();
        }
      });
  }

  onSuccessImport(resp: SinisterExcelLoadResponse): void {
    this.dismiss(ButtonsRole.ACCEPT, resp.sinisters);
  }

  private setForm(): void {
    this.form = new FormGroup<UpdateDebtSinisterModalForm>({
      file: new FormControl<File>(null, Validators.required)
    });
  }
}
