import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import {
  MyCustomEvent,
  PolicyIssue,
  Sinister,
  SinisterStatusEnum
} from '@core/models';
import {
  IonToggleCustomEvent,
  SearchbarCustomEvent,
  ToggleChangeEventDetail
} from '@ionic/core';
import { ChildRoutesEnum } from '@shared/models/insurance-page.model';
import { Subscription } from 'rxjs';

import { OpenClosedFilter } from '../../models/insurances-management-filters.model';
import { MonthSelect } from '../../models/month-year-selects.model';
import { generateArrayOfYears, months } from '../../utils/month-year.utils';

@Component({
  selector: 'el-buen-inquilino-insurances-management-filters',
  templateUrl: './insurances-management-filters.component.html'
})
export class InsurancesManagementFiltersComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() showSearchbar = false;
  @Input() showHomeOwnerFilter = true;
  @Input() showOpenClosedFilter = true;
  @Input() homeOwnersList: string[] = [];
  @Input() items: PolicyIssue[] | Sinister[] = [];
  @Input() showDateFilter = true;
  @Input() showFilterPolicyNumber = true;
  @Input() showFilterAddress = true;
  @Input() sinisterPage = false;
  @Input() isHomeownerPage = false;
  @Input() showInsuranceCodeSearchbar = false;
  @Input() showFirstDateButton = true;
  @Input() showSecondDateButton = false;
  @Input() type: ChildRoutesEnum;
  @Input() showUnreadSinisters = false

  searchbarValue: string = null;
  insurnaceCodeSearchbarValue: string = null;
  homeOwnerFilterValue = '';
  filterPolicyNumber: string;
  defaultMonth: string = null;
  defaultYear: number = null;
  selectedDate: string;
  monthSelect: MonthSelect[] = months;
  years: number[] = generateArrayOfYears();
  openClosedFilter: OpenClosedFilter = OpenClosedFilter.UNRESOLVED;
  openClosedFilterEnum = OpenClosedFilter;
  showClearDateBtn = false;
  childRoutesEnum = ChildRoutesEnum;
  renovation = false;
  inProgressCancel = false;
  unreadSinisters = false
  sinisterStatusEnum = SinisterStatusEnum;
  nearbyRenovationActived = false;

  portals: string[] = [];
  currentPortalFilter = '';
  originalFloors: string[] = [];
  floors: string[] = [];
  currentFloorFilter = '';
  originalDoors: string[] = [];
  doors: string[] = [];
  currentDoorFilter = '';

  @Output() filterBySearchbarEmitter = new EventEmitter<string>();
  @Output() filterByInsuranceCodeSearchbarEmitter = new EventEmitter<string>();
  @Output() filterByHomeOwnerEmitter = new EventEmitter<string>();
  @Output() filterByOpenClosedEmitter = new EventEmitter<OpenClosedFilter>();
  @Output() filterByDateEmitter = new EventEmitter<string>();
  @Output() filterByRenovationEmitter = new EventEmitter<boolean>();
  @Output() filterByUnreadSinistersEmitter = new EventEmitter<boolean>();
  @Output() filterByInProgressCancelEmitter = new EventEmitter<boolean>();

  @Output() getPoliciesEmiter = new EventEmitter<string>();
  @Output() getPolicyByIdEmiter = new EventEmitter<string>();
  @Output() addressFiltersEmiter = new EventEmitter<{
    portal: string;
    floor: string;
    door: string;
  }>();

  cleanFiltersFromParent = false;

  clearFiltersSubscription: Subscription;
  clearFiltersFromParentSubscription: Subscription;

  get showNearbyRenovations(): boolean {
    return this.isHomeownerPage && this.type === ChildRoutesEnum.ACTIVE;
  }

  get showInProgressCancel(): boolean {
    return this.type === ChildRoutesEnum.CANCELLED;
  }

  get nearbyRenovationFill(): string {
    if (this.nearbyRenovationActived) {
      return 'solid';
    } else {
      return 'outline';
    }
  }

  constructor(private insuranceService: InsurancesApiService) {}

  ngOnInit(): void {
    this.subscribeToClearFilter();
    this.pageActions();
  }

  pageActions(): void {
    if (this.sinisterPage) {
      this.filterByOpenClosed();
    }
  }

  filterByOpenClosed(): void {
    this.filterByOpenClosedEmitter.emit(this.openClosedFilter);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.items?.length > 0) {
      this.fillAddressSelects();
    }
  }

  subscribeToClearFilter(): void {
    this.clearFiltersSubscription =
      this.insuranceService.clearPoliciesFilter.subscribe(() => {
        this.homeOwnerFilterValue = '';
        this.filterPolicyNumber = null;
        this.currentPortalFilter = '';
        this.currentFloorFilter = '';
        this.currentDoorFilter = '';
      });
    this.clearFiltersFromParentSubscription =
      this.insuranceService.clearPoliciesFilterFromParent.subscribe(() => {
        this.homeOwnerFilterValue = '';
        this.filterPolicyNumber = null;

        this.currentPortalFilter = '';
        this.currentFloorFilter = '';
        this.currentDoorFilter = '';
        // Seteamos esta variable para que si se vuelve al componente, no se
        // haga otra llamada de buscar pólizas
        this.cleanFiltersFromParent = true;
        this.getPolicies();
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeSearchbar(e: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.searchbarValue = (e.target.value as string).toLowerCase() || null;

    this.filterBySearchbarEmitter.emit(this.searchbarValue);

    this.checkIsThereAnyFilter();
  }

  filterByHomeOwner(event: Event): void {
    // Guardamos el valor del filtro en la variable global
    this.homeOwnerFilterValue = (event as CustomEvent<MyCustomEvent>).detail
      .value as string;

    this.filterByHomeOwnerEmitter.emit(this.homeOwnerFilterValue);

    this.checkIsThereAnyFilter();
  }

  addressFilterChange(
    event: Event,
    filter: 'portal' | 'stairs' | 'floor' | 'door'
  ): void {
    const value = (event as CustomEvent<MyCustomEvent>).detail.value as string;

    switch (filter) {
      case 'portal':
        this.currentPortalFilter = value;
        this.filterFloors(value, !this.currentPortalFilter ? true : false);
        break;
      case 'floor':
        this.currentFloorFilter = value;
        this.filterDoors(this.currentPortalFilter, this.currentFloorFilter);
        break;
      case 'door':
        this.currentDoorFilter = value;
        this.emitAddressFilters();
        break;
    }

    if (!!value) {
      this.checkIsThereAnyFilter();
    }
  }

  checkTextValue(): void {
    if (
      (this.filterPolicyNumber === '' || this.filterPolicyNumber === null) &&
      !this.cleanFiltersFromParent
    ) {
      if (this.sinisterPage) {
        this.getPolicyByIdEmiter.emit(null);
      } else {
        this.getPolicies();
      }
    }

    if (this.cleanFiltersFromParent) {
      this.cleanFiltersFromParent = false;
    }
  }

  searchByPolicyId(): void {
    this.homeOwnerFilterValue = '';
    this.getPolicyByIdEmiter.emit(this.filterPolicyNumber);

    this.checkIsThereAnyFilter();
  }

  getPolicies(): void {
    const date = `01/${this.defaultMonth}/${this.defaultYear}`;

    if (this.isHomeownerPage) {
      this.filterByDateEmitter.emit(date);
      this.showClearDateBtn = true;
    } else {
      this.getPoliciesEmiter.emit(date);
      this.checkIsThereAnyFilter();
    }
  }

  emitDateFilters(): void {
    let month = this.defaultMonth;
    let year = this.defaultYear;

    if (!month) {
      month = `${new Date().getMonth() + 1}`;
    }

    if (!year) {
      year = new Date().getFullYear();
    }

    const date = `01/${month}/${year}`;

    this.filterByDateEmitter.emit(date);
    this.showClearDateBtn = true;
  }

  clearDate(): void {
    this.filterByDateEmitter.emit(null);
    this.showClearDateBtn = false;
    this.defaultMonth = null;
    this.defaultYear = null;
  }

  checkIsThereAnyFilter(): void {
    if (
      this.homeOwnerFilterValue ||
      this.filterPolicyNumber ||
      !!this.currentPortalFilter ||
      !!this.currentFloorFilter ||
      !!this.currentDoorFilter ||
      !!this.searchbarValue ||
      !!this.insurnaceCodeSearchbarValue
    ) {
      this.insuranceService.isThereAnyFilterEmitter.emit(true);
    } else {
      this.insuranceService.isThereAnyFilterEmitter.emit(false);
    }
  }

  handleChangeMonth(event: Event): void {
    this.defaultMonth = (event as CustomEvent<MyCustomEvent>).detail
      .value as string;
  }

  handleChangeYear(event: Event): void {
    this.defaultYear = (event as CustomEvent<MyCustomEvent>).detail
      .value as number;
  }

  changeShowOpenClosed(event: Event): void {
    this.openClosedFilter = (event as CustomEvent<MyCustomEvent>).detail
      .value as OpenClosedFilter;
    this.filterByOpenClosed();
  }

  renovationChange(event: IonToggleCustomEvent<ToggleChangeEventDetail>): void {
    this.filterByRenovationEmitter.emit(event.detail.checked);
  }

  inProgressCancelChange(
    event: IonToggleCustomEvent<ToggleChangeEventDetail>
  ): void {
    this.filterByInProgressCancelEmitter.emit(event.detail.checked);
  }

  unreadSinistersChange(event: IonToggleCustomEvent<ToggleChangeEventDetail>): void {
    this.filterByUnreadSinistersEmitter.emit(event.detail.checked);
  }

  changeInsuranceCodeSearchbar(e: SearchbarCustomEvent): void {
    this.insurnaceCodeSearchbarValue = e.detail.value?.toLowerCase() || null;

    this.filterByInsuranceCodeSearchbarEmitter.emit(
      this.insurnaceCodeSearchbarValue
    );

    this.checkIsThereAnyFilter();
  }

  ngOnDestroy(): void {
    this.clearFiltersSubscription?.unsubscribe();
    this.clearFiltersFromParentSubscription?.unsubscribe();
  }

  private filterFloors(portal?: string, reset?: boolean): void {
    let floors: string[] = [];
    let items: PolicyIssue[] = JSON.parse(
      JSON.stringify(this.items)
    ) as PolicyIssue[];

    this.currentFloorFilter = '';

    if (portal && !reset) {
      if (portal) {
        items = items?.filter((a: PolicyIssue) => a.asset?.portal === portal);
      }

      floors = items?.map((a: PolicyIssue) => a.asset?.floor);
    } else {
      floors = items?.map((a: PolicyIssue) => a.asset?.floor);
    }

    floors = [...new Set(floors)];
    floors = this.deleteUndefined(floors);
    if (floors?.length > 0) {
      floors = this.deleteNull(floors);
    }
    if (floors?.length > 0) {
      floors = this.deleteBlank(floors);
    }

    this.floors = this.orderStringArray(floors);

    this.filterDoors(
      portal,
      this.currentFloorFilter,
      this.orderStringArray(floors),
      !this.currentPortalFilter && !this.currentFloorFilter ? true : false
    );
  }

  private filterDoors(
    portal?: string,
    floor?: string,
    floors?: string[],
    reset?: boolean
  ): void {
    let doors: string[] = [];
    let items: PolicyIssue[] = JSON.parse(
      JSON.stringify(this.items)
    ) as PolicyIssue[];

    this.currentDoorFilter = '';

    if ((portal || floor || floors?.length > 0) && !reset) {
      if (portal) {
        items = items?.filter((a: PolicyIssue) => a.asset?.portal === portal);
      }

      if (floor) {
        items = items?.filter((a: PolicyIssue) => a.asset?.floor === floor);
      } else if (floors?.length > 0) {
        items = items?.filter((a: PolicyIssue) =>
          floors.includes(a.asset?.floor)
        );
      }

      doors = items?.map((a: PolicyIssue) => a.asset?.door);
    } else {
      doors = items?.map((a: PolicyIssue) => a.asset?.door);
    }

    doors = [...new Set(doors)];
    doors = this.deleteUndefined(doors);
    if (doors?.length > 0) {
      doors = this.deleteNull(doors);
    }
    if (doors?.length > 0) {
      doors = this.deleteBlank(doors);
    }
    this.doors = this.orderStringArray(doors);

    this.emitAddressFilters();
  }

  private deleteUndefined(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === undefined);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private deleteNull(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === null);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private deleteBlank(array: string[]): string[] {
    const index = array.findIndex((a: string) => a === '');
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  private orderStringArray(array: string[]): string[] {
    return array.sort((a: string, b: string) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }

  private fillAddressSelects(): void {
    let { portalsList, floorsList, doorsList } =
      this.insuranceService.getAddressFilters(this.items as PolicyIssue[]);

    portalsList = [...new Set(portalsList)];
    portalsList = this.deleteUndefined(portalsList);
    portalsList = this.deleteNull(portalsList);
    portalsList = this.deleteBlank(portalsList);

    this.portals = this.orderStringArray(portalsList);

    floorsList = [...new Set(floorsList)];
    floorsList = this.deleteUndefined(floorsList);
    floorsList = this.deleteNull(floorsList);
    floorsList = this.deleteBlank(floorsList);

    this.floors = this.orderStringArray(floorsList);

    doorsList = [...new Set(doorsList)];
    doorsList = this.deleteUndefined(doorsList);
    doorsList = this.deleteNull(doorsList);
    doorsList = this.deleteBlank(doorsList);

    this.doors = this.orderStringArray(doorsList);
  }

  private emitAddressFilters(): void {
    this.addressFiltersEmiter.emit({
      portal: this.currentPortalFilter,
      floor: this.currentFloorFilter,
      door: this.currentDoorFilter
    });
  }
}
