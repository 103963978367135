import { FormControl } from '@angular/forms';

export enum BankAccountValidationStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}

export interface BankAccountValidation {
  creationDate: Date;
  name: string;
  accountNumber: string;
  response: BankAccountValidationStatus;
}

export interface ValidateBankAccountModalForm {
  accountOwner: FormControl<string>;
  ccc: FormControl<string>;
}

export interface TenantOwnershipRequest {
  userId: string;
  candidatureId: string;
  accountNumber: string;
}
